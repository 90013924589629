import { ProductOffer, ProductOfferType } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { PERCENT_SERVICE_CALENDLY_URL_TILES } from './appStoreUrls'
import { ProgramName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

const productOffersCatalog = {
  airslate: {
    type: ProductOfferType.PartnerProduct,
    name: 'Airslate',
    tracking: ProgramName.Airslate,
    description:
      'Learn how Airslate can help you create and manage workflows with its doc generation, e-signature, web form & automation platform',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Airslate.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cks5-zxf-czc',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  asana: {
    type: ProductOfferType.PartnerProduct,
    name: 'Asana',
    tracking: ProgramName.Asana,
    description: 'Get 50% off Asana’s work management tool with dedicated support from their team',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Asana.png'),
    primaryButton: {
      linkType: 'typeform',
      link: 'ttps://form.typeform.com/to/Va07vpDP',
      typeformId: 'Va07vpDP',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  atlassian: {
    type: ProductOfferType.PartnerProduct,
    name: 'Atlassian',
    tracking: ProgramName.Atlassian,
    description: 'Get 75% off work management products including Jira, Confluence, and Trello',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Atlassian.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.atlassian.com/teams/nonprofits?utm_source=percent&utm_medium=comarketing&utm_campaign=P:multiproduct%7CO:foundation%7CF:discovery%7CW:nonprofit%7CI:percent-marketplace%7C',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  auth0: {
    type: ProductOfferType.PartnerProduct,
    name: 'Auth0',
    tracking: ProgramName.Auth0,
    description: 'Get 50% off Auth0 to protect your data, donor information and other sensitive resources',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Auth0.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://auth0.com/percent-validation-form',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  box: {
    type: ProductOfferType.PartnerProduct,
    name: 'Box',
    tracking: ProgramName.Box,
    description: 'Get at least 30% off Box’s cloud storage solution',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Box.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/ckpd-pvy-jzz',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  canva: {
    type: ProductOfferType.PartnerProduct,
    name: 'Canva',
    tracking: ProgramName.Canva,
    description: 'Get Canva for free to start creating high-impact graphics and marketing materials',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Canva.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-canva',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  docusign: {
    type: ProductOfferType.PartnerProduct,
    name: 'Docusign',
    tracking: ProgramName.Docusign,
    description: 'Get at least 30% off Docusign’s electronic signature solution',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Docusign.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cpkt-sjn-vnb',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  eventbrite: {
    type: ProductOfferType.PartnerProduct,
    name: 'Eventbrite',
    tracking: ProgramName.Eventbrite,
    description: "Get 50% off Eventbrite's premium event ticketing and marketing tools",
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Eventbrite.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.eventbrite.com/l/npo/',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  givebutter: {
    type: ProductOfferType.PartnerProduct,
    name: 'Givebutter',
    tracking: ProgramName.Givebutter,
    description: 'Schedule a call to discuss Givebutter’s free, all-in-one fundraising platform',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Givebutter.png'),
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  google: {
    type: ProductOfferType.PartnerProduct,
    name: 'Google',
    tracking: ProgramName.Google,
    description: 'Get 70% off Google’s workspace, a collection of productivity and collaboration tools',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-google',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  hootsuite: {
    type: ProductOfferType.PartnerProduct,
    name: 'Hootsuite',
    tracking: ProgramName.Hootsuite,
    description: 'Get 75% off Hootsuite to help with your social media management',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Hootsuite.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cmcr-s75-twd',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  linkedInFundraise: {
    type: ProductOfferType.PartnerProduct,
    name: 'LinkedIn Fundraise',
    tracking: ProgramName.LinkedInFundraise,
    description: 'Get 75% off LinkedIn’s Sales Navigator Core to connect with new donors',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Linkedin.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.poweredbypercent.com/linkedin',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  linkedInHire: {
    type: ProductOfferType.PartnerProduct,
    name: 'LinkedIn Hire',
    tracking: ProgramName.LinkedInHire,
    description: 'Get 75% off LinkedIn’s Recruiter Lite to find and hire new talent',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Linkedin.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.poweredbypercent.com/linkedin',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  mondayCom: {
    type: ProductOfferType.PartnerProduct,
    name: 'monday.com',
    tracking: ProgramName.MondayCom,
    description: `Get 10 free users and a 70% discount for any additional user in each one of monday.com's products`,
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/MondayCom.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://monday.com/nonprofits?utm_source=partner&utm_campaign=Goodstack&utm_medium=Marketplace_011024',
      linkText: 'productOffers.getStarted'
    },
    secondaryButton: undefined
  },
  newRelic: {
    type: ProductOfferType.PartnerProduct,
    name: 'New Relic',
    tracking: ProgramName.NewRelic,
    description: 'Get 1,000 GB data and Three Full Platform Users for free each month',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/NewRelic.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://newrelic.com/social-impact/signup',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  nordlayer: {
    type: ProductOfferType.PartnerProduct,
    name: 'NordLayer',
    tracking: ProgramName.NordLayer,
    description: 'Get 60% off NordLayer’s network security solution',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Nordlayer.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cmcr-sw8-6hr',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  okta: {
    type: ProductOfferType.PartnerProduct,
    name: 'Okta',
    tracking: ProgramName.Okta,
    description: 'Get 50% off Okta to add an extra layer of security to your organization',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Okta.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-okta',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  openAi: {
    type: ProductOfferType.PartnerProduct,
    name: 'OpenAI',
    tracking: ProgramName.OpenAI,
    description: 'Access ChatGPT Team at $20 per month per user, or get 50% off ChatGPT Enterprise',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Openai.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.poweredbypercent.com/openai',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  sage: {
    type: ProductOfferType.PartnerProduct,
    name: 'Sage',
    tracking: ProgramName.Sage,
    description: 'Get 50% off Sage Intacct to help with your accounting needs',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Sage.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=fN0yPvZBLUmho8WOsCz0-PAYGA5Uma5Dk674Gui_6yJUQk8wQktGOVBXRlNIVTg4NUJPSTlHSTcxOC4u',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  splunk: {
    type: ProductOfferType.PartnerProduct,
    name: 'Splunk',
    tracking: ProgramName.Splunk,
    description: 'Get Splunk for free to help your organisation manage large amounts of data',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Splunk.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.splunk.com/en_us/about-us/splunk-pledge/nonprofit-license-application.html',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  twilio: {
    type: ProductOfferType.PartnerProduct,
    name: 'Twilio',
    tracking: ProgramName.Twilio,
    description: "Get $100 credits and further discounted offers on Twilio's communication and engagment tool",
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Twilio.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.twilio.org/support-and-resources/impact-access-program',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  }
} as const

type ProductOffersKeys = keyof typeof productOffersCatalog

export const productOffers = productOffersCatalog as Record<ProductOffersKeys, ProductOffer>

import { TitleAndBullets } from '@percent/cause-dashboard/common/components/TitleAndBullets'
import { DETAIL_BULLET_POINTS_DEFAULT, detailTitle } from '@percent/cause-dashboard/constants/emailVerificationData'
import { Grid, Box } from '@material-ui/core'
import styles from './InvitationStatusScreen.module.scss'
import { useTranslation } from 'react-i18next'
import { Button, Alert, Spacer, Text, Heading } from '@percent/lemonade'
import { useHistory } from 'react-router-dom'
import { CausesPortalLogo } from '@percent/cause-dashboard/common/components/causesPortalLogo/CausesPortalLogo'

enum ErrorKey {
  InviteExpired = 'iam/invite_expired',
  InviteAlreadyAccepted = 'iam/invite_already_accepted',
  UserAlreadyJoinedOrganisation = 'iam/user_already_joined_organisation',
  UserAlreadyJoinedDifferentOrganisation = 'iam/user_already_joined_different_organisation'
}

interface InvitationStatusScreenProps {
  errorCode?: string
  hasPartnerAccount?: boolean
}

export function InvitationStatusScreen({
  errorCode,
  hasPartnerAccount = false
}: Readonly<InvitationStatusScreenProps>) {
  const { t } = useTranslation()
  const history = useHistory()

  const errorToAlertMap: Record<string, { variant: 'info' | 'error'; title: string; children: string | JSX.Element }> =
    {
      [ErrorKey.InviteExpired]: {
        variant: 'error',
        title: t('errorMessage.inviteExpired.title'),
        children: t('errorMessage.inviteExpired.children')
      },
      [ErrorKey.InviteAlreadyAccepted]: {
        variant: 'error',
        title: t('errorMessage.inviteAlreadyAccepted.title'),
        children: ''
      },
      [ErrorKey.UserAlreadyJoinedOrganisation]: {
        variant: 'info',
        title: t('errorMessage.userAlreadyJoinedOrg.title'),
        children: ''
      },
      [ErrorKey.UserAlreadyJoinedDifferentOrganisation]: {
        variant: 'error',
        title: t('errorMessage.userAlreadyJoinedDifferentOrg.title'),
        children: (
          <span>
            {t('errorMessage.userAlreadyJoinedDifferentOrg.children')}
            <a href="https://poweredbypercent.zendesk.com/hc/en-us/requests/new?ticket_form_id=22114960488593">
              {t('emailVerification.helpCenter')}
            </a>
          </span>
        )
      },
      otherError: {
        variant: 'error',
        title: t('errorMessage.unknownError.title'),
        children: (
          <span>
            {t('errorMessage.unknownError.children')}
            <a href="https://poweredbypercent.zendesk.com/hc/en-us/requests/new?ticket_form_id=22114960488593">
              {t('emailVerification.helpCenter')}
            </a>
          </span>
        )
      }
    }

  const getErrorFromMap = () => {
    if (!errorCode || !Object.keys(errorToAlertMap).includes(errorCode)) {
      return errorToAlertMap.otherError
    }

    return errorToAlertMap[errorCode]
  }

  const { children, variant, title } = getErrorFromMap()

  const handleNavigation = () => {
    history.push('/signin')
  }

  return (
    <Grid container spacing={0} className={styles.ctn}>
      <Grid item sm={12} md={6} className={styles.acceptInvitationSection}>
        <CausesPortalLogo />
        <Box className={styles.statusContainer} data-testid="invitation-status-screen">
          <Heading level="h4">{t('invite.accept.title')}</Heading>
          <Spacer size={6} axis="vertical" />
          {errorCode && (
            <>
              <Alert variant={variant} title={title}>
                {' '}
                {children} <span data-testid={errorCode} />
              </Alert>
              <Spacer size={6} axis="vertical" />
              {(title === errorToAlertMap[ErrorKey.InviteAlreadyAccepted].title ||
                title === errorToAlertMap[ErrorKey.UserAlreadyJoinedOrganisation].title ||
                title === errorToAlertMap[ErrorKey.UserAlreadyJoinedDifferentOrganisation].title) && (
                <Button size="large" stretch onPress={handleNavigation} data-testid="signin-button">
                  {t('button.signIn')}
                </Button>
              )}
            </>
          )}
          {hasPartnerAccount && (
            <>
              <Alert variant="info" title={t('typography.account.alreadyExists')}></Alert>
              <Spacer size={6} axis="vertical" />
              <Button size="large" stretch onPress={handleNavigation} data-testid="signin-button">
                {t('button.signIn')}
              </Button>
            </>
          )}
        </Box>
      </Grid>
      <Grid className={styles.titleAndBullets} item sm={12} md={6}>
        <TitleAndBullets title={t(detailTitle)} bullets={DETAIL_BULLET_POINTS_DEFAULT} />
      </Grid>
    </Grid>
  )
}

import { Typography } from '@material-ui/core'
import { TICKET_SUPPORT_LINK } from '@percent/cause-dashboard/constants/support'
import { Alert } from '@percent/lemonade'

import styles from './BankDetailsReview.module.scss'
import { BankDetailsReviewProps } from './BankDetailsReview.types'

export function BankDetailsReview({
  reviewBadgeText = '',
  reviewText = '',
  reviewLinkText,
  variant
}: BankDetailsReviewProps) {
  return (
    <>
      {' '}
      <div className={styles.bankDetailsBadge}>
        <Alert variant={variant}>{reviewBadgeText}</Alert>
      </div>
      {reviewText && (
        <Typography className={styles.reviewText}>
          {reviewText}{' '}
          <a
            href={`${TICKET_SUPPORT_LINK}?ticket_form_id=14155148527633`}
            target="_blank"
            rel="noreferrer"
            className={styles.formLink}
          >
            {reviewLinkText}
          </a>
          .
        </Typography>
      )}
    </>
  )
}

import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { MutableRefObject, useEffect, useRef } from 'react'

const TermlyBanner = () => {
  const scriptRef = useRef<HTMLScriptElement>(null) as MutableRefObject<HTMLScriptElement>
  const {
    authState: { user: userLoggedIn }
  } = useAuthState()

  useEffect(() => {
    if (userLoggedIn) {
      const acceptButton = document.querySelector('[data-tid="banner-accept"]')
      if (acceptButton instanceof HTMLElement) {
        acceptButton.click()
      }
      return
    }

    if (scriptRef.current) {
      return
    }

    const script = document.createElement('script')
    script.src = 'https://app.termly.io/resource-blocker/8cbb43ca-0785-4379-bf5d-be3ab7e572d7?autoBlock=on'
    script.async = true
    scriptRef.current = script
    document.body.appendChild(script)

    const termlyOptsScript = document.createElement('script')
    termlyOptsScript.type = 'text/javascript'
    termlyOptsScript.innerHTML = 'window.TERMLY_CUSTOM_BLOCKING_MAP = { "goodstack.io": "essential" }'
    document.body.appendChild(termlyOptsScript)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(termlyOptsScript)
    }
  }, [userLoggedIn])

  return null
}

export default TermlyBanner

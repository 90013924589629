import { Logo } from '@percent/lemonade'
import styles from './CausesPortalLogo.module.scss'

export function CausesPortalLogo() {
  return (
    <div className={styles.causesPortalLogo}>
      <Logo />
    </div>
  )
}

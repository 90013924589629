import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'

import { Button, ButtonText, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './ForgotPassword.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '../../routes/Routes'
import PaperPlane from '@percent/cause-dashboard/common/assets/images/paper-plane.png'
import { useState } from 'react'
import { emailRegex } from '@percent/utility'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { CentralCardLayout } from '@percent/domain/iam'

export function ForgotPassword() {
  const { authService } = useServices()
  const [{ success, errorMessage }, { apiFunc: forgotPassword }] = useMutation(authService.forgotPassword)
  const [resendSuccess, setResendSuccess] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string().required('Required').matches(emailRegex, t('errorMessage.validEmail'))
      }),
    validateOnMount: true,
    onSubmit: ({ email }) => {
      forgotPassword(email)
      setResendSuccess(false)
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid } = formik

  if (success || resendSuccess) {
    return (
      <CentralCardLayout
        heading={t('form.checkInbox')}
        CustomHeaderImage={<img src={PaperPlane} alt="paper plane" />}
        footer={<Footer />}
      >
        <Typography variant="body1" className={styles.passwordResetText}>
          {t('form.checkInboxDetails', { email: values.email })}
        </Typography>
        <Button
          stretch
          size="large"
          type="button"
          data-testid="auth-active-button"
          disabled={resendSuccess}
          onPress={() => {
            forgotPassword(values.email)
            setResendSuccess(true)
          }}
        >
          {t('form.resendEmail')}
        </Button>
      </CentralCardLayout>
    )
  }

  return (
    <CentralCardLayout
      heading={t('form.forgotPasswordTitle')}
      handleSubmit={handleSubmit}
      error={errorMessage}
      footer={<Footer />}
    >
      <Typography className={styles.passwordText} variant="body2" data-testid="forgot-password-subtitle">
        {t('form.forgotPasswordSubtitle')}
      </Typography>
      <FormikProvider value={formik}>
        <FormField
          label={t('form.email')}
          status={touched.email && errors.email ? 'danger' : 'default'}
          statusMessage={errors.email}
          data-testid="email"
          necessity="required"
        >
          <TextInput
            name="email"
            placeholder={t('form.enterEmailAddress')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </FormField>
      </FormikProvider>
      <Spacer size={5} axis="vertical" />
      <Button stretch size="large" type="submit" data-testid="auth-active-button" disabled={isSubmitting || !isValid}>
        {t('button.sendEmail')}
      </Button>
      <div className={styles.passwordFormFooter}>
        <Typography variant="body2" className={styles.footerText}>
          {t('typography.lookingForLogin')}
        </Typography>
        <ButtonText onPress={() => history.push(RoutePath.SIGNIN)}>{t('typography.signInHere')}</ButtonText>
      </div>
    </CentralCardLayout>
  )
}

import React from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { Button, FormField, Spacer, TextInput, Text, ButtonText } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import styles from './ConfirmationCodeStep.module.scss'
import { ConfirmationCodeViewProps } from './ConfirmationCodeStep.types'
import { CentralCardLayout } from '@percent/domain/iam'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'

export function ConfirmationCodeStep({ errorMessage, isLoading, verifyFunc }: ConfirmationCodeViewProps) {
  const { t } = useTranslation()
  const { push } = useHistory()

  const formik = useFormik({
    initialValues: {
      confirmationCode: ''
    },
    validationSchema: () =>
      yup.object().shape({
        confirmationCode: yup
          .string()
          .required('Required')
          .matches(/^[0-9]+$/, t('errorMessage.onlyDigits'))
          .min(6, t('errorMessage.max6Digit'))
          .max(6, t('errorMessage.max6Digit'))
      }),
    onSubmit: ({ confirmationCode }: { confirmationCode: string }) => {
      verifyFunc({
        otpCode: confirmationCode
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <CentralCardLayout heading={t('2fa.enterConfirmationCode')} handleSubmit={handleSubmit}>
      <div className={styles.container}>
        <Text align="left">{t('2fa.enterConfirmationCodePart2')}</Text>
        <Spacer size={6} axis="vertical" />
        <FormikProvider value={formik}>
          <FormField
            label={t('form.enterSixDigit')}
            status={touched.confirmationCode && errors.confirmationCode ? 'danger' : 'default'}
            statusMessage={errors.confirmationCode || errorMessage}
            data-testid="confirmationCode"
            necessity="required"
          >
            <TextInput
              name="confirmationCode"
              placeholder={t('form.placeholderSixDigit')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmationCode}
            />
          </FormField>
        </FormikProvider>
        <Spacer size={8} axis="vertical" />
        <Button size="large" type="submit" loading={isLoading} stretch>
          {t('button.verify')}
        </Button>
        <Spacer size={8} axis="vertical" />
        <div className={styles.cancelButtonWrapper}>
          <ButtonText onPressEnd={() => push(RoutePath.MY_ACCOUNT)}>{t('button.cancelSetup')}</ButtonText>
        </div>
      </div>
    </CentralCardLayout>
  )
}

import { ReactNode } from 'react'
import { Card, Typography, Grid } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import styles from './ClaimSearchCardView.module.scss'
import { Spacer } from '@percent/lemonade'

type ClaimSearchCardViewProps = {
  title: string
  subTitle: string
  detail: string
  cardAction: ReactNode
}

export function ClaimSearchCardView({ title, subTitle, detail, cardAction }: ClaimSearchCardViewProps) {
  const { t } = useTranslation()

  return (
    <Card className={styles.cardContainer}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" color="secondary">
            {title}
          </Typography>
          <Spacer size={2} axis="vertical" />
          <Grid container>
            <Grid item xs={6} md={2}>
              <Typography variant="body1">{t('typography.address')}</Typography>
            </Grid>
            <Grid item xs={6} md={10}>
              <Typography variant="body1" color="textSecondary">
                {subTitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Spacer size={2} axis="vertical" />
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography variant="body1">{t('typography.registerNumber')}</Typography>
            </Grid>
            <Grid item xs={6} md={10}>
              <Typography variant="body1" color="textSecondary">
                {detail}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} md={6} className={styles.buttonContainer}>
          {cardAction}
        </Grid>
      </Grid>
    </Card>
  )
}

import { AppBar, Breadcrumbs, Grid, MenuItem, Toolbar, Menu, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useLocation, Link, useHistory, Redirect } from 'react-router-dom'
import classNames from 'classnames/bind'

import { Avatar, Icon } from '@percent/lemonade'
import { ReactComponent as NavigateNextIcon } from '@percent/cause-dashboard/common/assets/images/navigate-next-icon.svg'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './TopBar.module.scss'
import { ReactComponent as DropdownSVG } from '@percent/cause-dashboard/common/assets/images/dropdown.svg'
import { replaceDashWithEmptySpace } from '@percent/cause-dashboard/common/utility/utility'
import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { selectAuthState, SET_LOGOUT } from '@percent/cause-dashboard/context/auth'
import { useAnalytics } from '@percent/cause-dashboard/common/hooks/useAnalytics/useAnalytics'
import { useNotificationBar } from '@percent/cause-dashboard/common/hooks/useNotificationBar/useNotificationBar'

const cx = classNames.bind(styles)

export function TopBar() {
  const { t } = useTranslation()
  const history = useHistory()
  const { authDispatch } = useAuthDispatch()
  const { authService } = useServices()
  const {
    authState: appState,
    authState: { organisation, user }
  } = useAuthState()
  const { claimAccepted, rejectedBankDetails } = selectAuthState(appState)
  const analytics = useAnalytics()

  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { isOpened: isNotificationBarOpened } = useNotificationBar()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUserProfile = () => {
    setAnchorEl(null)
    history.push('/my-account')
  }

  const handleMyTeam = () => {
    setAnchorEl(null)
    history.push('/users')
  }

  const handleLogout = () => {
    authService.logout().then(() => {
      analytics?.reset()
      authDispatch({
        type: SET_LOGOUT
      })
    })

    return <Redirect to="/" />
  }

  const handleCreateLink = (link: string) => {
    return link.replace(/\b\w/g, l => l.toUpperCase())
  }

  let paths = pathname.split('/')

  if (paths[1] === 'users') {
    paths = ['My team']
  } else if (paths[1].includes('-')) {
    paths = ['', replaceDashWithEmptySpace(paths[1])]
  }

  return (
    <AppBar position="static">
      <Toolbar
        className={cx({
          verifiedNotification: claimAccepted,
          unverifiedNotification: !claimAccepted,
          rejectedBankDetails: rejectedBankDetails,
          notificationBarOpened: isNotificationBarOpened,
          notificationBarClosed: !isNotificationBarOpened
        })}
      >
        <Breadcrumbs className={styles.breadcrumbs} separator={<NavigateNextIcon />} aria-label="breadcrumb">
          {paths.map(
            (path: string, id: number) =>
              path && (
                <Link
                  className={cx({
                    breadcrumbActive: id === paths.length - 1,
                    breadcrumb: id !== paths.length - 1,
                    'link--disabled': id === paths.length - 1
                  })}
                  key={path}
                  to={`/${path.replace(' ', '-')}`}
                >
                  {handleCreateLink(path)}
                </Link>
              )
          )}
        </Breadcrumbs>
        <Grid direction="row-reverse" container>
          <MenuItem onClick={handleClick} className={styles.menuItem}>
            {organisation?.claimedAt && <Avatar image={organisation?.logo} alt={organisation?.name || ''} />}
            <div className={styles.userDetail} data-testid="navigation-bar-user-details">
              <Typography className={styles.nameText} variant="body1">
                {user?.fullName ?? user?.preferredName ?? user?.email}
              </Typography>
              <Typography className={styles.roleText} variant="body2">
                <span data-testid={'user-role'}> {user?.role?.displayName} </span>
              </Typography>
            </div>
            <DropdownSVG className={anchorEl ? styles.dropdown : undefined} />
          </MenuItem>
          <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={styles.userProfileMenu}
            >
              <MenuItem className={styles.userProfileMenuItem} onClick={handleUserProfile}>
                <Icon name="user-circle" size={6} color="gray500" />
                {t('card.myAccount')}
              </MenuItem>
              {user?.organisationId && (
                <MenuItem className={styles.userProfileMenuItem} onClick={handleMyTeam} data-testid={'my-team-btn'}>
                  <Icon name="users" size={6} color="gray500" />
                  {t('menu.myTeam')}
                </MenuItem>
              )}
              <MenuItem
                className={styles.userProfileMenuItem}
                onClick={handleLogout}
                data-testid="navigation-bar-logout-button"
              >
                <Icon name="log-out" size={6} color="gray500" />
                {t('menu.logout')}
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { useTranslation } from 'react-i18next'
import { FormStep, optionType } from '@percent/cause-dashboard/common/components/multiStepForm/MultiStepForm.types'
import { Box, Grid } from '@material-ui/core'
import { MultiStepForm } from '@percent/cause-dashboard/common/components/multiStepForm/MultiStepForm'
import { TitleAndBullets } from '@percent/cause-dashboard/common/components/TitleAndBullets'
import styles from '../multiStepSignUp/MultiStepSignUp.module.scss'
import { DETAIL_BULLET_POINTS_DEFAULT, detailTitle } from '@percent/cause-dashboard/constants/emailVerificationData'
import { FormikValues } from 'formik'
import { emailRegex, isValidWebsiteURL } from '@percent/utility'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import useCountries from '@percent/cause-dashboard/common/hooks/useCountries/useCountries'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { APIErrorHandler } from '@percent/cause-dashboard/common/library/APIErrorHandler'
import { RegistriesType } from '@percent/cause-dashboard/services/registries/registries.types'
import { ErrorView } from '@percent/cause-dashboard/common/components/ErrorView'
import { ConfirmationView } from './ConfirmationView'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { Loader } from '@percent/cause-dashboard/common/components'
import { AddOrganizationProps } from '@percent/cause-dashboard/services/organization/organization.types'
import { useMyCountryFromIp } from '@percent/cause-dashboard/common/hooks/useMyCountryFromIp/useMyCountryFromIp'
import { useLocalUserPreference } from '@percent/cause-dashboard/common/hooks/useLocalUserPreference/useLocalUserPreference'

const mapRegistries = (registries?: RegistriesType[]): optionType[] => {
  return registries
    ? registries?.map(registry => {
        return {
          label: registry.englishName || registry.name,
          value: registry.name
        }
      })
    : []
}

export const MultiStepAddOrganization = () => {
  const { t } = useTranslation()
  const countries = useCountries()
  const [country, setCountry] = useState('')
  const { registriesService, organizationService } = useServices()
  const [registryData, setRegistryData] = useState<RegistriesType[] | null>(null)
  const [isRegistryDataError, setIsRegistryDataError] = useState('')
  const [{ errorMessage, isLoading, success, error }, { apiFunc: addOrganization }] = useMutation(
    organizationService.addOrganization
  )
  const [formError, setFormError] = useState<string | undefined>(undefined)

  const countriesOptions = countries.map(({ name, code }: { name: string; code: string }) => ({
    label: name,
    value: code
  }))

  const { country: myCountry } = useMyCountryFromIp({
    useLocalCache: true
  })

  const [userPreferenceLocalCountry] = useLocalUserPreference('country')

  const getRegistryData = useCallback(
    (countryCode: string) => {
      registriesService
        .getRegistries(countryCode)
        .then(regData => {
          setRegistryData(regData?.data?.data)
        })
        .catch(error => {
          setIsRegistryDataError(APIErrorHandler(error?.response?.data?.error))
        })
    },
    [registriesService]
  )

  useEffect(() => {
    if (country) {
      getRegistryData(country)
    }
  }, [country, getRegistryData])

  useEffect(() => {
    // Error is wrongly typed as string inside useMutation hook
    // However not sure if it has always same structure, so left untouched
    const anyError = error as any

    if (anyError && anyError.code && anyError.code === 'bad_request') {
      const errorString = anyError.message + '\n' + anyError.reasons?.join('\n')
      setFormError(errorString)
    } else {
      setFormError(errorMessage)
    }
  }, [errorMessage, error])

  const formSteps = useMemo(() => {
    const aboutOrgStepValidationSchema = Yup.object().shape({
      country: Yup.string().required(),
      name: Yup.string()
        .required(t('form.isRequired', { name: 'Organization name' }))
        .min(3, t('form.minLength', { name: 'Organization name', length: 3 }))
        .max(255, t('form.maxLength', { name: 'Organization name', length: 255 })),
      website: isValidWebsiteURL()
        .trim()
        .required(t('form.isRequired', { name: 'Website' }))
        .min(3, t('form.minLength', { name: 'Website', length: 3 }))
        .max(255, t('form.maxLength', { name: 'Website', length: 255 }))
    })

    const registryOrgStepValidationSchema = Yup.object().shape({
      registry: Yup.string().optional(),
      registryId: Yup.string()
        .required(t('form.isRequired', { name: 'Registry ID' }))
        .min(3, t('form.minLength', { name: 'Registry ID', length: 3 }))
        .max(255, t('form.maxLength', { name: 'Registry ID', length: 255 })),
      documentProof: Yup.mixed()
        .required(t('form.isRequired', { name: 'Document proof' }))
        .test('fileFormat', t('errorMessage.invalidFileFormat'), value => {
          if (value) {
            return !!/(png|jpeg|pdf)/i.exec(value.type)
          }
          return true
        })
        .test('fileSize', t('errorMessage.maxFileSize'), value => {
          if (value) {
            return value.size <= 5232660
          }
          return true
        })
    })

    const emailOrgStepValidationSchema = Yup.object().shape({
      email: Yup.string()
        .required(t('form.isRequired', { name: 'Email' }))
        .matches(emailRegex, t('errorMessage.validEmail'))
    })

    const formSteps: FormStep[] = [
      {
        stepTitle: t('form.multiStepAddOrganization.title'),
        stepSubtitle: t('form.multiStepAddOrganization.subtitle'),
        fields: [
          {
            name: 'country',
            label: t('form.multiStepAddOrganization.country.label'),
            placeholder: t('form.multiStepAddOrganization.country.placeholder'),
            options: countriesOptions,
            type: 'select',
            defaultValue: userPreferenceLocalCountry ?? myCountry ?? ''
          },
          {
            name: 'name',
            label: t('form.multiStepAddOrganization.organizationName.label'),
            placeholder: t('form.multiStepAddOrganization.organizationName.placeholder'),
            description: t('form.multiStepAddOrganization.organizationName.description'),
            type: 'text'
          },
          {
            name: 'website',
            label: t('form.multiStepAddOrganization.website.label'),
            placeholder: t('form.multiStepAddOrganization.website.placeholder'),
            type: 'text'
          }
        ],
        validationSchema: aboutOrgStepValidationSchema,
        buttonText: t('button.next'),
        selectValue: setCountry,
        dataTestId: 'next-button'
      },
      {
        stepTitle: t('form.multiStepAddOrganization.title'),
        stepSubtitle: t('form.multiStepAddOrganization.subtitleTwo'),
        fields: [
          {
            name: 'registryId',
            label: t('form.multiStepAddOrganization.registryId.label'),
            placeholder: t('form.multiStepAddOrganization.registryId.placeholder'),
            type: 'text'
          },
          {
            name: 'registry',
            label: t('form.multiStepAddOrganization.registry.label'),
            placeholder: t('form.multiStepAddOrganization.registry.placeholder'),
            options: (registryData && [...mapRegistries(registryData)]) || [],
            description: t('form.multiStepAddOrganization.registry.description'),
            type: 'select',
            optional: true
          },
          {
            name: 'documentProof',
            label: t('form.multiStepAddOrganization.documentProof.label'),
            placeholder: t('form.multiStepAddOrganization.documentProof.placeholder'),
            type: 'file',
            dataTestId: 'fileUpload',
            fileUploadLabel: t('form.multiStepAddOrganization.documentProof.fileUploadLabel')
          }
        ],
        validationSchema: registryOrgStepValidationSchema,
        buttonText: t('button.next')
      },
      {
        stepTitle: t('form.multiStepAddOrganization.titleEmail'),
        stepSubtitle: t('form.multiStepAddOrganization.subtitleEmail'),
        fields: [
          {
            name: 'email',
            label: t('form.multiStepAddOrganization.email.label'),
            placeholder: t('form.multiStepAddOrganization.email.placeholder'),
            type: 'text',
            description: t('form.multiStepAddOrganization.email.description')
          }
        ],
        validationSchema: emailOrgStepValidationSchema,
        buttonText: t('button.submitInformation')
      }
    ]

    return formSteps
  }, [t, countriesOptions, registryData, userPreferenceLocalCountry, myCountry])

  if (isRegistryDataError) {
    return <ErrorView errorMessage={isRegistryDataError} />
  }

  if (isLoading) {
    return <Loader loaderStyle="fullScreen" />
  }

  const onSubmit = async (values: FormikValues) => {
    addOrganization({
      formData: values as AddOrganizationProps['formData'],
      registryNameFallback: t('form.multiStepAddOrganization.registry.cantFind')
    })
  }

  return (
    <Grid container spacing={0} className={styles.container}>
      <Grid item sm={12} md={6} className={styles.signUpSection}>
        <Box className={styles.formWrapper}>
          {(success && <ConfirmationView />) || (
            <MultiStepForm steps={formSteps} onSubmit={onSubmit} errorMessage={formError} isLoading={isLoading} />
          )}
        </Box>
        <Footer />
      </Grid>
      <Grid className={styles.titleAndBullets} item sm={12} md={6}>
        <TitleAndBullets title={t(detailTitle)} bullets={DETAIL_BULLET_POINTS_DEFAULT} />
      </Grid>
    </Grid>
  )
}

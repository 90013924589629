import { List, ListItem } from '@material-ui/core'
import { ReactComponent as DonationFormTabLogo } from '@percent/cause-dashboard/common/assets/images/direct-donation.svg'
import { ReactComponent as DonationsTabLogo } from '@percent/cause-dashboard/common/assets/images/donation.svg'
import { ReactComponent as MoneyMilestonesLogo } from '@percent/cause-dashboard/common/assets/images/money-milestones.svg'
import { ReactComponent as PayoutsTabLogo } from '@percent/cause-dashboard/common/assets/images/payouts.svg'
import { ReactComponent as ProductOffersLogo } from '@percent/cause-dashboard/common/assets/images/product-offers.svg'
import { ReactComponent as CharityProfileLogo } from '@percent/cause-dashboard/common/assets/images/user-circle.svg'
import { CustomMenuItem } from '@percent/cause-dashboard/common/components/CustomMenuItem'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useFeatureFlag } from '@percent/cause-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { useTranslation } from 'react-i18next'
import { Feature } from '../../providers/SplitSdkProvider'
import styles from './SideMenu.module.scss'
import { Logo } from '@percent/lemonade'

function CPLogo(props: any) {
  return <CharityProfileLogo {...props} />
}
function DonationsLogo(props: any) {
  return <DonationsTabLogo {...props} />
}
function DonationFormLogo(props: any) {
  return <DonationFormTabLogo {...props} />
}
function MMLogo(props: any) {
  return <MoneyMilestonesLogo {...props} />
}
function POLogos(props: any) {
  return <ProductOffersLogo {...props} />
}
function PayoutsLogo(props: any) {
  return <PayoutsTabLogo {...props} />
}

export function SideMenu() {
  const { t } = useTranslation()
  const { authState } = useAuthState()
  const { claimAccepted } = selectAuthState(authState)
  const featureFlags = useFeatureFlag()

  const myNonprofit: {
    name: string
    to: string
    logo: (_: any) => JSX.Element
    soon: boolean
    claimed?: boolean
    flag?: Feature
  }[] = [
    {
      name: t('menu.charityProfile'),
      to: '/profile',
      logo: CPLogo,
      soon: false
    },
    {
      name: t('menu.donations'),
      to: '/donations',
      logo: DonationsLogo,
      soon: false,
      claimed: true
    },
    {
      name: t('menu.donationForm'),
      to: '/donation-form',
      logo: DonationFormLogo,
      soon: false,
      claimed: true,
      flag: 'donationForm'
    },
    {
      name: t('menu.payouts'),
      to: '/payouts',
      logo: PayoutsLogo,
      soon: false,
      claimed: true
    },
    {
      name: t('menu.milestones'),
      to: '/milestones',
      logo: MMLogo,
      soon: false,
      claimed: true
    },
    {
      name: t('menu.productOffers'),
      to: '/product-offers',
      logo: POLogos,
      soon: false
    }
  ]

  return (
    <div className={styles.sideMenuContainer}>
      <List className={styles.list}>
        <ListItem className={styles.causesPortalLogo}>
          <Logo variant="causes" />
        </ListItem>
        {myNonprofit
          .filter(a => (a.flag ? featureFlags[a.flag] : true))
          .map(item => {
            if (item.claimed) {
              return (
                claimAccepted && (
                  <CustomMenuItem name={item.name} to={item.to} Logo={item.logo} soon={item.soon} key={item.name} />
                )
              )
            }

            return <CustomMenuItem name={item.name} to={item.to} Logo={item.logo} soon={item.soon} key={item.name} />
          })}
      </List>
    </div>
  )
}

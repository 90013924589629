import { Loader } from '@percent/cause-dashboard/common/components'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import styles from './AgentVerificationDocuments.module.scss'
import { AgentVerificationDocumentsStatus } from './agentVerificationDocumentsStatus/AgentVerificationDocumentsStatus'
import { useLocation } from 'react-router-dom'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { AgentVerificationDocuments } from './AgentVerificationDocuments'
import { AgentVerificationStatus } from '@percent/cause-dashboard/services/agentVerification/AgentVerification.types'
import { AVDocumentsStatus } from './agentVerificationDocumentsStatus/AgentVerificationDocumentsStatus.types'
import { useState } from 'react'
import { Logo } from '@percent/lemonade'

const mapDocumentUploadStatus = (
  status: AgentVerificationStatus | undefined,
  hasError: boolean,
  documentsSubmitted: boolean
): AVDocumentsStatus => {
  if (documentsSubmitted) return AVDocumentsStatus.SUBMITTED

  if (status === AgentVerificationStatus.PENDING_USER_VERIFICATION || hasError || !status)
    return AVDocumentsStatus.EXPIRED

  if ([AgentVerificationStatus.PENDING_REVIEW, AgentVerificationStatus.PENDING].includes(status))
    return AVDocumentsStatus.PENDING

  return AVDocumentsStatus.PROCESSED
}

export const AgentVerificationDocumentsContainer = () => {
  const [documentsSubmitted, setDocumentsSubmitted] = useState(false)
  const { search } = useLocation()
  const { agentVerificationService } = useServices()

  const agentVerificationToken = new URLSearchParams(search).get('token') || ''

  const [{ data: agentVerificationDocumentStatus, isLoading, error }, { refresh }] = useQuery(
    agentVerificationService.getAgentVerificationDocumentStatus,
    agentVerificationToken
  )

  const [{ data: agentVerificationConfirmation, isLoading: isLoadingAgent }] = useQuery(
    agentVerificationService.getConfirmAgentVerificationRequest,
    agentVerificationToken
  )

  const onDocumentsSubmitted = () => {
    setDocumentsSubmitted(true)
    refresh()
  }

  const statusData = agentVerificationDocumentStatus?.data
  const status = mapDocumentUploadStatus(statusData?.status, !!error, documentsSubmitted)
  const shouldDisplayStatusScreen =
    statusData?.status &&
    [
      AgentVerificationStatus.APPROVED,
      AgentVerificationStatus.REJECTED,
      AgentVerificationStatus.PENDING_USER_VERIFICATION
    ].includes(statusData?.status)

  const agentData = agentVerificationConfirmation?.data

  if ((isLoadingAgent || isLoading) && !statusData) return <Loader />

  return (
    <div className={styles.agentVerificationDocumentsContainer}>
      <Logo className={styles.goodstackLogo} />
      {statusData && !shouldDisplayStatusScreen && !statusData?.hasUploadedDocuments && !error ? (
        <AgentVerificationDocuments
          agentVerificationToken={agentVerificationToken}
          agentVerificationId={statusData?.id}
          onDocumentsSubmitted={onDocumentsSubmitted}
          organisationName={statusData?.organisationName}
        />
      ) : (
        <AgentVerificationDocumentsStatus
          status={status}
          agentVerificationStatus={statusData?.status}
          partnerName={statusData?.partnerName}
          organisationId={agentData?.data?.organisationId}
          email={agentData?.data?.email}
        />
      )}

      <div className={styles.footerWrapper}>
        <Footer />
      </div>
    </div>
  )
}

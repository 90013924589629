import { NotificationBar } from '@percent/cause-dashboard/common/components/notification/NotificationBar'
import { useNotificationBar } from '@percent/cause-dashboard/common/hooks/useNotificationBar/useNotificationBar'
import { config } from '@percent/cause-dashboard/config'
import { SideMenu } from '../sideMenu/SideMenu'
import { TopBar } from '../topbar/TopBar'
import styles from './DashboardLayout.module.scss'

interface DashboardLayoutProps {
  children: React.ReactNode
}

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const notification = useNotificationBar()
  const shiftDownToAccomodateNotification = notification.isOpened ? 46 : 0

  return (
    <div className={styles.container} style={{ marginTop: shiftDownToAccomodateNotification }}>
      <NotificationBar config={config} />
      <div className={styles.sideMenuWrapper} style={{ top: shiftDownToAccomodateNotification }}>
        <SideMenu />
      </div>

      <div className={styles.pageWrapper}>
        <TopBar />
        <div className={styles.pageContent}>{children}</div>
      </div>
    </div>
  )
}

import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { SET_USER_VERIFIED, selectAuthState } from '@percent/cause-dashboard/context/auth'
import { useAuthDispatch, useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { CentralCardLayout } from '@percent/domain/iam'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import CheckMark from '@percent/cause-dashboard/common/assets/images/email-verified-check-mark.png'
import { Alert, Button, Spacer } from '@percent/lemonade'
import { RoutePath } from '../../routes/Routes'

export function AccountVerification() {
  const { authService } = useServices()
  const { search } = useLocation()
  const { push } = useHistory()
  const { authDispatch } = useAuthDispatch()
  const { t } = useTranslation()

  const hash = new URLSearchParams(search).get('userHash')

  const appState = useAuthState()
  const authState = selectAuthState(appState.authState)

  const [{ success, errorMessage, isLoading }, { apiFunc: verifyAccount }] = useMutation(
    authService.verifyAccount,
    () => {
      if (authState.loggedIn) {
        authDispatch({
          type: SET_USER_VERIFIED
        })
      }
    }
  )

  useEffect(() => {
    if (hash) {
      verifyAccount(hash)
    } else {
      push(RoutePath.SIGNIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CentralCardLayout
      heading={t('accountVerification.emailVerified')}
      CustomHeaderImage={<img src={CheckMark} alt="check mark" />}
      footer={<Footer />}
    >
      {errorMessage ? (
        <div style={{ marginTop: '-20px' }} data-testid="error-view">
          <Alert variant="error">{errorMessage}</Alert>
          <Spacer size={6} axis="vertical" />
        </div>
      ) : (
        success && (
          <>
            <Spacer size={6} axis="vertical" />
            <Button
              stretch
              size="large"
              type="button"
              data-testid="auth-active-button"
              loading={isLoading}
              onPress={() => push(RoutePath.SIGNIN)}
            >
              {t('accountVerification.clickHereToSignIn')}
            </Button>
          </>
        )
      )}
    </CentralCardLayout>
  )
}

import { productOffers } from '@percent/cause-dashboard/app/productOffers/data/productOffers'
import { ProductOffer } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { percentServices } from '@percent/cause-dashboard/app/productOffers/data/percentServices'
import { CategoryName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

export interface IProductCategory {
  name: string
  tracking: CategoryName
  description: string
  offers: ProductOffer[]
  featured?: boolean
}

const categories: IProductCategory[] = [
  {
    name: 'Top tools',
    tracking: CategoryName.TopTools,
    description: 'In a hurry? Discover & get started with our top tools and integration support',
    offers: [
      productOffers.openAi,
      percentServices.googleWorkspaceSupport,
      percentServices.googleAdsSupport,
      productOffers.asana,
      productOffers.mondayCom
    ],
    featured: true
  },
  {
    name: 'Expert services',
    tracking: CategoryName.ExpertServices,
    description:
      'Vetted service experts to maximize setup, integration, and ongoing management of your software and tools',
    offers: [
      percentServices.googleWorkspaceSupport,
      percentServices.googleAdsSupport,
      percentServices.managedItSupport,
      percentServices.socialMediaManagement,
      percentServices.websiteCmsSupport
    ]
  },
  {
    name: 'Discounted software',
    tracking: CategoryName.DiscountedSoftware,
    description: 'The world’s leading technology nonprofit discounts, all in one place',
    offers: [
      productOffers.airslate,
      productOffers.asana,
      productOffers.atlassian,
      productOffers.auth0,
      productOffers.box,
      productOffers.canva,
      productOffers.docusign,
      productOffers.eventbrite,
      productOffers.givebutter,
      productOffers.google,
      productOffers.hootsuite,
      productOffers.linkedInFundraise,
      productOffers.linkedInHire,
      productOffers.mondayCom,
      productOffers.newRelic,
      productOffers.nordlayer,
      productOffers.okta,
      productOffers.openAi,
      productOffers.sage,
      productOffers.splunk,
      productOffers.twilio
    ]
  }
]

export const categorizedProducts = categories.map((category, index) => ({
  id: index,
  ...category
})) as Array<{ id: number } & IProductCategory>

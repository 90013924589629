import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import debounce from 'lodash.debounce'
import {
  ProgramGenericEventKind,
  MarketplaceProgrammeVisitedSource,
  ProgrammeViewEventKind,
  MarketingMetadataKey,
  MarketingMetadataKeyStore,
  ProgramTracking
} from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
interface ProgramVisitedTrackingProps {
  source: MarketplaceProgrammeVisitedSource
  targetUrl: string
  programmeOfferName: string
  programTracking?: ProgramTracking
  productCategory?: string
  metadata?: Record<MarketingMetadataKeyStore, string | boolean | number>
}

interface ProgramViewEventProps {
  eventKind: ProgrammeViewEventKind
  programmeName?: string
  page: string
}

interface GenericMarketplaceEventProps {
  eventKind: ProgramGenericEventKind
  programmeName: string
  source: string
  metadata?: Record<MarketingMetadataKey, string | boolean | number>
}

export const useMarketplaceAnalytics = () => {
  const { track } = useCausesPortalAnalytics()
  const { authState } = useAuthState()
  const { organisation } = authState

  const trackProgramVisited = debounce(
    (props: ProgramVisitedTrackingProps) =>
      track('Program Visited', {
        country: organisation?.countryCode,
        organisation: organisation?.displayName,
        organisationId: organisation?.id,
        programmeName: props.programmeOfferName,
        programTracking: props.programTracking,
        clickedFrom: props.source,
        targetUrl: props.targetUrl,
        productCategory: props.productCategory,
        metadata: props.metadata
      }),
    1000
  )

  const trackProgramViewEvent = debounce(
    ({ eventKind, programmeName, page }: ProgramViewEventProps) =>
      track(eventKind, {
        programmeName,
        page
      }),
    1000
  )

  const trackProgramEvent = debounce(
    ({ eventKind, programmeName, source, metadata }: GenericMarketplaceEventProps) =>
      track(eventKind, {
        programmeName,
        source,
        metadata
      }),
    1000
  )

  return {
    trackProgramVisited,
    trackProgramViewEvent,
    trackProgramEvent
  }
}

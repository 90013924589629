import { useTranslation } from 'react-i18next'
import { ExpiredSessionHandle } from '@percent/domain/iam'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'

export function ExpiredSessionHandleContainer() {
  const { t } = useTranslation()

  return (
    <ExpiredSessionHandle
      heading={t('typography.sessionHasExpired')}
      description={t('typography.loggedOutFromThisSession')}
      buttonText={t('button.logIn')}
      redirectRoute="/signin"
      footer={<Footer />}
    />
  )
}
